import * as React from 'react'
import Layout from '../components/layout'
import './app.scss';
import { Breadcrumbs, BreadcrumbsItem } from '@ui5/webcomponents-react';
import { useNavigate } from "react-router-dom";
import configData from "../config.json";

const ContactUs = () => {
  const navigate = useNavigate();

  function handleBooking(e) {
    e.preventDefault();
window.open(configData.BOOKING_URL, 'booking', 'toolbar=0,location=0,menubar=0').focus();
  }

  return (
    <div>
              <div>
            <div className="container" >
            <Breadcrumbs>
                <BreadcrumbsItem href="/">Home</BreadcrumbsItem>
                <BreadcrumbsItem href="/contactus">Contact us</BreadcrumbsItem>
            </Breadcrumbs>
            </div>
        </div>
        <div className="wg-page-header-image" >
        <img src={require("../images/frontpage.webp")}></img>
        </div>
        <div className="wg-page-wrapper">
          <h1 class="wg-page-title">Contact us</h1>
          <div className="teaserBannerSplitCMS contain">
            <div className="imageHolderCMS"><img src={require("../images/contactus.jpg")}></img></div>
            <div className="richText contain">
                <p><strong>Phone:</strong> <a href="tel:01225 421 309">01225 421 309</a></p>
                <p>Jiffylube Bath is the base for all of our workshop services and the home of our master technicians. Whether you're looking for an MOT, servicing, repairs or anything else motor related, our team would be delighted to help you.</p>
                <p><strong>Jiffylube Opening Hours</strong></p>
                <ul>
<li>Mon - Fri: 9am-6pm</li>
<li>Sat: 9am-1pm</li>
<li>Sun: Closed</li>
</ul>
            </div>
          </div>
  
          <iframe
  width="720"
  height="450"
  style={{border:0}}
  loading="lazy"
  allowfullscreen
  referrerpolicy="no-referrer-when-downgrade"
  src="https://www.google.com/maps/embed/v1/place?key=AIzaSyAMIkb0Mf9FDRET3g_YZmdC8RXvLgLRQ-k&amp;q=Weston+Garage+Bath,BA14BX">
</iframe>

         

        </div>

    </div>
  )
}
export default ContactUs