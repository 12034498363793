import * as React from "react";
import "../app.scss";
import {
  Breadcrumbs,
  BreadcrumbsItem,
  Dialog,
  Carousel,
  Button,
  Bar,
  ObjectPage,
  ObjectPageSection,
  Grid,
} from "@ui5/webcomponents-react";

import { useState } from "react";

import { useNavigate } from "react-router-dom";
import configData from "../../config.json";

import chatImg from "../../images/chat.svg";
import mailImg from "../../images/mail.svg";
import Footer from "../../components/footer";
import ImageCarousel from "../components/ImageCarousel";
import Modal from "react-modal";

const Retail = () => {
  const navigate = useNavigate();
  const [dialogIsOpen, setDialogIsOpen] = useState(false);
  const [images, setimages] = React.useState([]);
  const [modalIsOpen, setIsOpen] = React.useState(false);

  const retailImages = {
    PointOfSale: [
      {
        src: require("../../images/screen01.png"),
        alt: "image sample 1",
        title: "POS being used on a phone",
      },
      {
        src: require("../../images/screen02.png"),
        alt: "image sample 2",
        title: "POS being used on a phone",
      },
      {
        src: require("../../images/screen04.png"),
        alt: "image sample 3",
        title: "POS being used on a phone",
      },
      {
        src: require("../../images/screen05.png"),
        alt: "image sample 3",
        title: "POS being used on a phone",
      },
      {
        src: require("../../images/screen06.png"),
        alt: "image sample 3",
        title: "POS being used on a phone",
      },
      {
        src: require("../../images/screen07.png"),
        alt: "image sample 3",
        title: "POS being used on a phone",
      },
      {
        src: require("../../images/screen08.png"),
        alt: "image sample 3",
        title: "POS being used on a phone",
      },
      {
        src: require("../../images/screen09.png"),
        alt: "image sample 3",
        title: "POS being used on a phone",
      },
      {
        src: require("../../images/screen10.png"),
        alt: "image sample 3",
        title: "POS being used on a phone",
      },
      {
        src: require("../../images/screen11.png"),
        alt: "image sample 3",
        title: "POS being used on a phone",
      },
      {
        src: require("../../images/screen12.png"),
        alt: "image sample 3",
        title: "POS being used on a phone",
      },
    ],
  };

  function handleBooking(e) {
    e.preventDefault();
    navigate("/booking");
  }

  function showPointOfSale(e) {
    e.preventDefault();
    setimages(retailImages["PointOfSale"]);
    setIsOpen(true);
  }

  return (
    <div>
      <ImageCarousel
        modalIsOpen={modalIsOpen}
        setIsOpen={setIsOpen}
        images={images}
      ></ImageCarousel>

      <Dialog
        open={dialogIsOpen}
        className="footerPartNoPadding"
        stretch={true}
        footer={
          <Bar
            design="Footer"
            endContent={
              <Button
                onClick={() => {
                  setDialogIsOpen(false);
                }}
              >
                Close
              </Button>
            }
          />
        }
        headerText="Dialog Header"
      >
        <Carousel>
          <div>
            <div className="cf-modal-image-header">
              <p>POS being used on a phone</p>
            </div>
            <img alt="img-sample 1" src="../../images/screen1.png" />
          </div>
          <img
            alt="img-sample 2"
            src="https://sap.github.io/ui5-webcomponents/assets/images/sample2.jpg"
          />
          <img
            alt="img-sample 3"
            src="https://sap.github.io/ui5-webcomponents/assets/images/sample3.jpg"
          />
        </Carousel>
      </Dialog>


      <ObjectPage
        className="cf-objectPage"
        style={{ height: "calc(100vh - 6em)" }}
      >
        <ObjectPageSection
          aria-label="Overview"
          hideTitleText
          id="overview"
          titleText="Overview"
        >
          <div className="container cf-inner-header-container">
            <div className="row">
              <div className="col-6 cf-inner-header-container-inner">
                <h1 className="cf-inner-header">CogentFactors Retail</h1>
                <div className="cf-inner-header-content">
                  Simplify your point-of-sale processes. Gain real-time and
                  in-depth insights. Run your business productively and
                  efficiently.
                </div>
                <div className="cf-inner-button-container">
                  <a className="cf-inner-button-blue" href="/">
                    Request a demo
                  </a>

                </div>

              </div>
              <div className="col-6">
                <img
                  className="cf-inner-image"
                  src={require("../../images/frontpage-screenshot.png")}
                  alt=""
                />
              </div>
            </div>
          </div>
        </ObjectPageSection>
        <ObjectPageSection
          aria-label="PointOfSale"
          hideTitleText
          id="PointOfSale"
          titleText="Point of Sale"
        >
          <h2 className="cf-inner-header cf-inner-center cf-inner-features-header">
            Point of Sale
          </h2>

          <div className="container cf-inner-features-image-container">
            <div className="row">
              <div className="col-8">
                <img
                  className=""
                  alt=""
                  src={require("../../images/frontpage-screenshot-2.png")}
                  width="100%"
                  onClick={showPointOfSale}
                />

                <div class="cf-enlarge-container">
                  <div class="cf-enlarge" onClick={showPointOfSale} >
                    <span class="cf-enlarge-icon"></span>
                    <span class="cf-enlarge-text">Enlarge</span>
                  </div>
                </div>
              </div>
              <div className="col-4">
                <div>
                  <p class="UniversalLayout__paragraph--P9Rsu fontBook Body__normal--a_yv3">
                    <p>
                      Take advantage of rich POS functionalities that can be
                      used for different business scenarios
                    </p>
                    <ul className="cf-unorderedlist">
                      <li>Sell Sales Sets and generic articles</li>
                      <li>Select Customers and Articles</li>
                      <li>Sell and top-up gift cards</li>
                      <li>Change sales item details</li>
                      <li>Apply discounts to sales items</li>
                      <li>Cancel sales items and receipts</li>
                      <li>Central parking and fetching of receipt</li>
                      <li>Return sales items and receipts</li>
                    </ul>
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div className="cf-divider-section"></div>
        </ObjectPageSection>

        <ObjectPageSection
          aria-label="LoyaltyManagement"
          hideTitleText
          id="LoyaltyManagement"
          titleText="Loyalty Management"
        >
          <h2 className="cf-inner-header cf-inner-center cf-inner-features-header">
            Loyalty Management
          </h2>

          <div className="container cf-inner-features-image-container">
            <div className="row">
              <div className="col-4">
                <div>
                  <p class="UniversalLayout__paragraph--P9Rsu fontBook Body__normal--a_yv3">
                    <p>
                      Track loyalty transactions, loyalty points collection and
                      purchase history of your customers
                    </p>
                    <ul className="cf-unorderedlist">
                      <li>Easy configuration set-up for loyalty management</li>
                      <li>Integrate a mobile app to collect loyalty points</li>
                      <li>Define your own point calculation</li>
                      <li>
                        Customers can collect loyalty points at point-of-sale
                      </li>
                      <li>
                        Customers can redeem loyalty points at point-of-sale
                      </li>
                      <li>Track loyalty transactions of your customers </li>
                      <li>Generate loyalty reports to track Top Customers</li>
                    </ul>
                  </p>
                </div>
              </div>
              <div className="col-8">
                <img
                  className=""
                  alt=""
                  src={require("../../images/frontpage-screenshot-2.png")}
                  width="100%"
                />
              </div>
            </div>
          </div>

          <div className="cf-divider-section"></div>
        </ObjectPageSection>

        <ObjectPageSection
          aria-label="LoyaltyCoupons"
          hideTitleText
          id="LoyaltyCoupons"
          titleText="Loyalty Coupons"
        >
          <h2 className="cf-inner-header cf-inner-center cf-inner-features-header">
            Loyalty Coupons
          </h2>

          <div className="container cf-inner-features-image-container">
            <div className="row">
              <div className="col-8">
                <img
                  className=""
                  alt=""
                  src={require("../../images/frontpage-screenshot-2.png")}
                  width="100%"
                />
              </div>
              <div className="col-4">
                <div>
                  <p class="UniversalLayout__paragraph--P9Rsu fontBook Body__normal--a_yv3">
                    <p>
                      Include coupons into your sales processes at POS to
                      attract customers, or reward employees while saving money
                      on purchases.
                    </p>
                    <ul className="cf-unorderedlist">
                      <li>Out-of-the-box coupon management</li>
                      <li>Create coupons for customers and loyalty programs</li>
                      <li>Define your own discount conditions</li>
                      <li>Generate coupon reports </li>
                      <li>Apply coupons to sales items and receipts</li>
                      <li>Reward loyal customers with coupons</li>
                    </ul>
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div className="cf-divider-section"></div>
        </ObjectPageSection>

        <ObjectPageSection
          aria-label="GiftCards"
          hideTitleText
          id="GiftCards"
          titleText="Gift Cards"
        >
          <h2 className="cf-inner-header cf-inner-center cf-inner-features-header">
            Gift Cards
          </h2>

          <div className="container cf-inner-features-image-container">
            <div className="row">
              <div className="col-4">
                <div>
                  <p class="UniversalLayout__paragraph--P9Rsu fontBook Body__normal--a_yv3">
                    <p>
                      Include gift cards into your business to attract new
                      customers and keep existing ones engaged.
                    </p>
                    <ul className="cf-unorderedlist">
                      <li>Create and manage all gift cards for customers</li>
                      <li>Track all gift card transactions</li>
                      <li>Pay and redeem gift cards at point-of-sale</li>
                      <li>Top-up gift cards at point-of-sale</li>
                      <li>Digital gift card for customers to download</li>
                      <li>Buy gift card in shop A, and use it in shop B</li>
                      <li>Print gift cards at point-of-sale</li>
                    </ul>
                  </p>
                </div>
              </div>
              <div className="col-8">
                <img
                  className=""
                  alt=""
                  src={require("../../images/frontpage-screenshot-2.png")}
                  width="100%"
                />
              </div>
            </div>
          </div>

          <div className="cf-divider-section"></div>
        </ObjectPageSection>

        <ObjectPageSection
          aria-label="MobileOrder"
          hideTitleText
          id="MobileOrder"
          titleText="Mobile Order"
        >
          <h2 className="cf-inner-header cf-inner-center cf-inner-features-header">
            Mobile Order
          </h2>

          <div className="container cf-inner-features-image-container">
            <div className="row">
              <div className="col-8">
                <img
                  className=""
                  alt=""
                  src={require("../../images/frontpage-screenshot-2.png")}
                  width="100%"
                />
              </div>
              <div className="col-4">
                <div>
                  <p class="UniversalLayout__paragraph--P9Rsu fontBook Body__normal--a_yv3">
                    <p>
                      Let your customers place and pay orders online while
                      receiving a pick-up code to present at point-of-sale.
                    </p>
                    <ul className="cf-unorderedlist">
                      <li>
                        Configure the web application for ordering and paying
                      </li>
                      <li>Track all your orders from customers</li>
                      <li>Manage all payment providers</li>
                      <li>
                        Offer your customers new possibilities to make orders
                      </li>
                      <li>Let customers choose between Pick-up or Delivery</li>
                      <li>Pay online with mobile device</li>
                    </ul>
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div className="cf-divider-section"></div>
        </ObjectPageSection>

        <ObjectPageSection
          aria-label="TableService"
          hideTitleText
          id="TableService"
          titleText="Table Service"
        >
          <h2 className="cf-inner-header cf-inner-center cf-inner-features-header">
            Table Service
          </h2>

          <div className="container cf-inner-features-image-container">
            <div className="row">
              <div className="col-4">
                <div>
                  <p class="UniversalLayout__paragraph--P9Rsu fontBook Body__normal--a_yv3">
                    <p>
                      Select table and create order for customers with
                      Table-side ordering
                    </p>
                    <ul className="cf-unorderedlist">
                      <li>Table-side ordering </li>
                      <li>Split and combine bills</li>
                      <li>Transfer bills and tables to other serving staff</li>
                      <li>Combine and move tables</li>
                      <li>Select course for meals</li>
                      <li>
                        Add additional text for sales item (e.g. “Extra cheese”)
                      </li>
                      <li>Handling of tips</li>
                      <li>Send orders automatically to bar and kitchen</li>
                    </ul>
                  </p>
                </div>
              </div>
              <div className="col-8">
                <img
                  className=""
                  alt=""
                  src={require("../../images/frontpage-screenshot-2.png")}
                  width="100%"
                />
              </div>
            </div>
          </div>

          <div className="cf-divider-section"></div>
        </ObjectPageSection>
        <ObjectPageSection
          aria-label="RestaurantFeatures"
          hideTitleText
          id="RestaurantFeatures"
          titleText="Restaurant Features"
        >
          <h2 className="cf-inner-header cf-inner-center cf-inner-features-header">
            Commerce Cloud
          </h2>

          <div className="container cf-inner-features-image-container">
            <div className="row">
              <div className="col-8">
                <img
                  className=""
                  alt=""
                  src={require("../../images/frontpage-screenshot-2.png")}
                  width="100%"
                />
              </div>
              <div className="col-4">
                <div>
                  <p class="UniversalLayout__paragraph--P9Rsu fontBook Body__normal--a_yv3">
                    <p>
                      Grow your business through enterprise-grade e-commerce
                    </p>
                    <ul className="cf-unorderedlist">
                      <li>
                        Adopt new business models and customer touch points
                        quickly
                      </li>
                      <li>
                        Link supply chain and demand signals with support for
                        end-to-end processes, enabling profitable growth
                      </li>
                      <li>
                        Leverage insights to stay on top of customer behavior
                        and commerce performance
                      </li>
                      <li>
                        Use AI-based tools to offer relevant product
                        recommendations and improve product discovery
                      </li>
                    </ul>
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div className="cf-divider-section"></div>
        </ObjectPageSection>

        <ObjectPageSection
          aria-label="ContactUs"
          hideTitleText
          id="contactus"
          titleText="Contact us"
        >
          <h2 className="cf-inner-header cf-inner-center cf-inner-features-header">
            Questions? Get in touch!
          </h2>

          <div className="container ">
            <div className="row">
              <div className="col-12">
                <div className=" cf-inner-contact-container">
                  <div>
                    <div>
                      <div className="cf-inner-contact-title">Call us at</div>
                      <div className="cf-inner-contact-country">
                        United Kingdom
                      </div>
                      <div className="cf-inner-contact-phone">
                        <a
                          href="tel:07443634637"
                          data-tel-number="tel:07443634637"
                        >
                          <div className="fontMedium">07443 634 637</div>
                        </a>
                      </div>
                    </div>
                  </div>
                  <div className="cf-inner-contact-verticallinecontainer">
                    <span className="cf-inner-contact-verticalline"></span>
                  </div>
                  <div className="cf-inner-contact-actionbox">

                    <div className="cf-inner-contact-actionbox-row">
                      <div className="cf-inner-contact-imagesection">
                        <img
                          className="cf-inner-contact-actionbox-img"
                          src={mailImg}
                          alt="Contact us"
                        />
                      </div>
                      <div>
                        <a className="cf-inner-contact-actionbox-title" href="mailto:info@cogentfactors.com">
                          Contact us                          
                        </a>
                        <div className="cf-inner-contact-actionbox-content">
                          Send us your comments, questions, or feedback.
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="cf-rating-section">
            <div className="container">
              <div className="row">
                <div className="col-12">
                  <Grid>
                    <React.Fragment key=".0">
                      <div data-layout-span="XL8 L8 M8 S10">
                        <h2 class="Headline__xl--IwUvq PageRating__headline--zA1i1">
                          How is your experience with this page?
                        </h2>
                      </div>
                      <div data-layout-span="XL1 L1 M1 S1">
                        <Button design="Emphasized" icon="accept"></Button>
                      </div>
                      <div data-layout-span="XL1 L1 M1 S1">
                        <Button design="Emphasized" icon="decline"></Button>
                      </div>
                    </React.Fragment>
                  </Grid>
                </div>
              </div>
            </div>
          </div>
          <Footer></Footer>
        </ObjectPageSection>
      </ObjectPage>
    </div>
  );
};
export default Retail;
