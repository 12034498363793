import * as React from 'react'
import { useState } from 'react';
import { Navbar, Container, Nav } from 'react-bootstrap';
import { Icon } from '@ui5/webcomponents-react';
import './navbar.css';
import configData from "../config.json";
import Logo from "../images/jiffy-lube.svg"; //

const NavBar = (props) => {
  const [showNavMenu, setShowNavMenu] = useState(false);
  const toggleNavMenu = () => {
    setShowNavMenu(!showNavMenu);
    props.setShowMainContent(showNavMenu);
  }

  const showChatBot = (e) => {
    e.preventDefault();
    setShowNavMenu(false);
    props.setShowMainContent(true);
    props.setShowChatBot(true);
  }


  const openQuotation = (e) => {
    e.preventDefault();
    window.open(configData.BOOKING_URL + "quotation", "quotation", 'toolbar=0,location=0,menubar=0').focus();
  } 
  return (
    <div>
      <div hidden={true}>
      <svg xmlns="http://www.w3.org/2000/svg">
        <symbol id="navbar-chatbubble" viewBox="0 0 36 35">
          <path d="M35.5 15.595c0 3.34-1.335 6.5-3.723 9.147l1.079 9.529-10.423-4.322c-1.36.36-2.772.513-4.433.513-9.65 0-17.5-6.544-17.5-14.867S8.35.73 18 .73s17.5 6.543 17.5 14.866zM12 15h12v-2H12v2zm0 4h8v-2h-8v2z" fill="currentColor"></path>
        </symbol>
        <symbol id="navbar-login" viewBox="0 0 32 32">
          <path d="M16 17a6 6 0 0 1-6-6v-1a6 6 0 0 1 12 0v1a6 6 0 0 1-6 6zm7.32 2.98A5.02 5.02 0 0 1 27 24.8V28H5v-3.2a5.02 5.02 0 0 1 3.68-4.82c4.7-1.3 9.94-1.3 14.64 0z" fill="currentColor"></path>
        </symbol>
        </svg>
      </div>
<Navbar className="navbar navbar-expand-lg bg-dark">
  <Container className="cf-header" >
  <Navbar.Brand className="cf-logo"  href="/">Cogent Factors</Navbar.Brand>

    <Navbar.Collapse id="basic-navbar-nav">
    </Navbar.Collapse>

  </Container>
</Navbar>

{showNavMenu && <div className="navMenu">

<div className="navMenuUpper">

  <div className="col">
    <h2><a href="/servicing/mot">MOT</a></h2>
    <h2><a href="/servicing/service">Servicing</a></h2>
    <ul>
    <li><a href="/servicing/service/fullservice">Full service</a></li>
    <li><a href="/servicing/service/interimservice">Interim service</a></li>
    <li><a href="/servicing/service/vanservice">Van service</a></li>
    <li><a href="/servicing/service/hybridservice">Hybrid service</a></li>
    </ul>
  </div>

  <div className="col">
    <h2><a href="/servicing/seasonalchecks">Seasonal check</a></h2>
    <ul>
    <li><a href="/servicing/seasonalchecks/springcheck">Spring check</a></li>
    <li><a href="/servicing/seasonalchecks/wintercheck">Winter check</a></li>
    </ul>
  </div>


  <div className="col">
    <h2><a href="/maintenance">Maintenance</a></h2>
    <ul>
    <li><a href="/maintenance/airconditioningrecharge">Air condition recharge</a></li>
    <li><a href="/maintenance/airfilterreplace">Air filter replace</a></li>
    <li><a href="/maintenance/brakefluidservice">Brake fluid service</a></li>
    <li><a href="/maintenance/brakediscsandpads">Brake and disc pads</a></li>
    <li><a href="/maintenance/faultdiagnosis">Fault diagnosis</a></li>
    <li><a href="/maintenance/fuelfilterreplace">Fuel filter replace</a></li>
    <li><a href="/maintenance/headlight">Headlight or other bulb replace</a></li>
    </ul>
  </div>


  <div className="col">
    <h2><a href="/mechanicalRepairs">Mechanical Repairs</a></h2>
    <ul>
    <li><a href="/mechanicalRepairs/cambeltchanges">Cambelt changes</a></li>
    <li><a href="/mechanicalRepairs/clutchreplacement">Clutch replacement</a></li>
    <li><a href="/mechanicalRepairs/enginerebuilds">Engine rebuilds</a></li>
    <li><a href="/mechanicalRepairs/gearboxrepairs">Gearbox repairs</a></li>
    <li><a href="/mechanicalRepairs/suspensionrepairs">Suspension repairs</a></li>
    <li><a href="/mechanicalRepairs/turborepairs">Turbo repairs</a></li>
    <li><a href="/mechanicalRepairs/welding">Welding</a></li>
    </ul>
  </div>
  <div className="col">
    <h2><a href="/enginerebuilds">Engine rebuilds</a></h2>
    <ul>
    <li><a href="/enginerebuilds/seizedenginerepairs">Seized engine repairs</a></li>
    <li><a href="/enginerebuilds/enginesupplyservices">Engine service repairs</a></li>
    <li><a href="/enginerebuilds/enginerebuilds">Engine rebuilds</a></li>
    <li><a href="/enginerebuilds/fullkitpackages">Full kit package</a></li>
    <li><a href="/enginerebuilds/reconditionedengines">Reconditioned engines</a></li>
    <li><a href="/enginerebuilds/enginereplacements">Engine replacements</a></li>
    </ul>
  </div>
  <div className="col">
    <h2><a href="/carboncleaning">Engine carbon cleaning</a></h2>
    <ul>
    <li><a href="/carboncleaning/enginedecarbonisationservice">Engine decarbonisation service</a></li>
    <li><a href="/carboncleaning/fuelsavingservice">Fuel saving service</a></li>
    <li><a href="/carboncleaning/powerflowdeepclean">Powerflow deep clean</a></li>

    </ul>
  </div>

</div>

<div>

<div className="navMenuLower">

  <div className="col navMenuLowerSection">
  <div>
    <svg aria-hidden="true" className="navbar-footer-icon" focusable="false">
        <use href="#navbar-chatbubble"></use>
    </svg>
  </div>
  <div>
  <h2>Do you need help?</h2>
    <ul>
    <li><a href="/" onClick={showChatBot}>Start chat</a></li>
    <li><a href="tel:+01225 421 309">Call 01225 421 309</a></li>
    </ul>
    </div>
  </div>


</div>


</div>

</div> }

    </div>
  )
}
export default NavBar