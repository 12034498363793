import * as React from 'react'
import { ThemeProvider, Dialog, Bar, Button } from '@ui5/webcomponents-react';
import { Outlet } from "react-router-dom";
import { useState } from 'react';

import NavBar from "./navbar";



const pageStyles = {
    color: "#232129",
    fontFamily: "-apple-system, Roboto, sans-serif, serif",
  }


const Layout = ({ pageTitle }) => {
  const [showMainContent, setShowMainContent] = useState(true);
  const [showChatBot, setShowChatBot] = useState(false);

  return (
    <div style={pageStyles}>
      <title>{pageTitle}</title>
      
<ThemeProvider>
<div hidden={true}>
             <svg xmlns="http://www.w3.org/2000/svg">
      <symbol id="wg-arrow-circle" viewBox="0 0 24 24">
                <title>Arrow circle</title>
                <path d="M14.586 13H6v-2h8.586l-2.293-2.293 1.414-1.414L18.414 12l-4.707 4.707-1.414-1.414L14.586 13zM12 24C5.373 24 0 18.627 0 12S5.373 0 12 0s12 5.373 12 12-5.373 12-12 12zm0-2c5.523 0 10-4.477 10-10S17.523 2 12 2 2 6.477 2 12s4.477 10 10 10z" />
            </symbol>
            <symbol id="wg-white-headset" viewBox="0 0 44 48">
                <path d="M44 24v16.8c0 4.032-3.268 7.2-7.3 7.2H25v-1.9h11.7a5.3 5.3 0 0 0 5.3-5.3v-2.111A6.805 6.805 0 0 1 38 40h-7V23h11v-1c0-11.046-8.954-20-20-20S2 10.954 2 22v1h11v17H6c-2.988 0-6-2.076-6-5V22C0 9.85 9.85 0 22 0s22 9.85 22 22v2z" fill="currentColor"></path>
            </symbol>
            <symbol id="wg-chatbubble" viewBox="0 0 36 35">
          <path d="M35.5 15.595c0 3.34-1.335 6.5-3.723 9.147l1.079 9.529-10.423-4.322c-1.36.36-2.772.513-4.433.513-9.65 0-17.5-6.544-17.5-14.867S8.35.73 18 .73s17.5 6.543 17.5 14.866zM12 15h12v-2H12v2zm0 4h8v-2h-8v2z" fill="currentColor"></path>
        </symbol>
            </svg>
      </div> 

<NavBar showChatBot={showChatBot} setShowChatBot={setShowChatBot} setShowMainContent={setShowMainContent}></NavBar>

{showMainContent && <div className="mainContent">

      <main>
      <Outlet />
      </main>


</div>}
      </ThemeProvider>
    </div>
  )
}
export default Layout