import * as React from 'react'
import './footer.css';

const Footer = (props) => {


  const showChatBot = (e) => {
    e.preventDefault();
    props.setShowChatBot(!props.showChatBot);
  }

  return (
<footer>

    <div className="wg-footer-finisher">
            <div className="wg-hide-on-desktop">
                <div>© Cogent Factors 2023</div>
                <div>2 The Weal, Weston</div>
                <div>Bath, BA1 4EX</div>
            </div>
            <div className="wg-hide-on-mobile">
                <div className="wg-footer-finisher-copyright">© Cogent Factors 2023, 2 The Weal, Weston, Bath, BA1 4EX
                </div>
            </div>
    </div>

    <div>

    </div>
</footer>
  )
}
export default Footer