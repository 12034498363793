import {
  BrowserRouter,
  Routes,
  Route
} from "react-router-dom";
import React from 'react';
import './App.css';
import { useRef } from 'react';
import Layout from './components/layout'
import Home from "./pages/home";
import Retail from "./pages/retail/retail";
import ContactUs from "./pages/contactus";

function App() {
  
  return (
    <BrowserRouter>
    <Routes>
      <Route path="/" element={<Layout />}>
        <Route index element={<Retail />} />
        <Route path="retail/retail" element={<Retail />}/>
        <Route path="contactus" element={<ContactUs />} />
      </Route>
    </Routes>
  </BrowserRouter>
  );
}

export default App;
