import Modal from "react-modal";
import { Carousel } from "@ui5/webcomponents-react";
import './ImageCarousel.css';
import "./slick.css"; 
import "./slick-theme.css";
import Slider from "react-slick";

import * as React from "react";
const ImageCarousel = (props) => {
  function closeModal() {
    props.setIsOpen(false);
  }

  var settings = {
    dots: true
  };
  return (
    <Modal
      isOpen={props.modalIsOpen}
      contentLabel="Example Modal"
      className="cf-modal"
      onRequestClose={closeModal}
      shouldCloseOnOverlayClick={true}
    >
         <Slider {...settings}>
         {props.images.map((image) => (
            <div className="cf-modal-carousel-item" key={image.src}>
              <div className="cf-modal-image-header">
                <p>{image.title}</p>
              </div>
              <div className="cf-modal-image-container">
                  <img className="cf-modal-image" alt={image.alt} src={image.src} />
              </div>
            </div>
          ))}
        </Slider>
    </Modal>
  );
};
export default ImageCarousel;
